<template>
  <title>Aanvraag | Dividenduitkering</title>

  <div class="container-fluid" :class="{'cpbm': windowCheckerData.isMobileScreen}">
    <div class="row request-row">
      <div class="col-md-6 col-12 container-content" :class="{'cptl cpm cpbm min-height': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen, 'font-white': isDarkPage}">
        <div class="cmtm">
          <div class="cmbm">
            <h2 class="largeTextXl">Nieuwe aanvraag</h2>
          </div>
          <div class="du-controls-content d-flex">
            <div class="w-100">
              <div class="du-question-control-left d-md-inline-block d-block" :class="{'w-100': windowCheckerData.isTabletScreen}">
                <p>Dit is het overzicht van al uw aanvragen. Wilt u een nieuwe aanvraag doen? Na het beëindigen van uw aanvraag
                  zal de aanvraag verstuurd via uw opgegeven e-mailadres van uw account.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">

              <form @submit.prevent="checkOut">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-12 col-xl-6">
                  <p class="du-r-input-annotation">Voornaam*</p>
                  <span class="error mt-3 d-block" v-if="errors.surname && !isSurnameValid">{{ errors.surname }}</span>
                  <div class="du-question-input mt-4 w-100">
                    <input type="text" class="du-input du-r-input" id="surname" v-model="form.surname" @blur="validateField('surname')">
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-12 col-xl-6">
                  <p class="du-r-input-annotation">Achternaam*</p>
                  <span class="error mt-3 d-block" v-if="errors.name && !isNameValid">{{ errors.name }}</span>
                  <div class="du-question-input mt-4 w-100">
                    <input type="text" class="du-input du-r-input" id="name" v-model="form.name" @blur="validateField('name')">
                  </div>
                </div>

                <div class="col-12">
                  <p class="du-r-input-annotation">Bedrijfsnaam</p>
                  <span class="error mt-3 d-block" v-if="errors.company">{{ errors.company }}</span>
                  <div class="du-question-input mt-4 w-100">
                    <input type="text" class="du-input du-r-input" id="company" v-model="form.company" @blur="validateField('company')">
                  </div>
                </div>

                <div class="col-12">
                  <p class="du-r-input-annotation">BTW-nummer</p>
                  <span class="error mt-3 d-block" v-if="errors.btwNumber">{{ errors.btwNumber }}</span>
                  <div class="du-question-input mt-4 w-100">
                    <input type="text" class="du-input du-r-input" id="btw-number" v-model="form.btwNumber" @blur="validateField('btw-number')">
                  </div>
                </div>

                <div class="du-request-form-seperator"></div>

                <div class="du-content-subtitle du-request-subtitle mt-0">
                  <h3>Factuuradres</h3>
                </div>

                <div class="col-12 col-md-8">
                  <p class="du-r-input-annotation">Straat & huisnummer</p>
                  <span class="error mt-3 d-block" v-if="errors.address">{{ errors.address }}</span>
                  <div class="du-question-input mt-4 w-100">
                    <input type="text" class="du-input du-r-input" id="address" v-model="form.address" @blur="validateField('address')">
                  </div>
                </div>

                <div class="col-12 col-md-4">
                  <p class="du-r-input-annotation">Postcode</p>
                  <span class="error mt-3 d-block" v-if="errors.zipcode">{{ errors.zipcode }}</span>
                  <div class="du-question-input mt-4 w-100">
                    <input type="text" class="du-input du-r-input du-input-zipcode" id="address"  maxlength="6" v-model="form.zipcode" @blur="validateField('zipcode')">
                  </div>
                </div>

                <div class="du-request-form-seperator"></div>
                <div class="col-12">
                  <p class="du-r-input-annotation">Kortingscode invoeren</p>
                  <span class="error mt-3 d-block" v-if="errors.discount">{{ errors.discount }}</span>
                  <div class="du-question-input mt-4 w-100 d-flex align-content-center">
                    <input type="text" class="du-input du-r-input du-input-discount" id="discount" v-model="form.discount">
                    <div class="du-question-control-right d-inline-block" v-on:click="isDiscountValid(form.discount)">
                      <button class="du-button">
                        INWISSELEN
                        <i class="fa-solid fa-chevron-right thick-icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="du-request-form-seperator"></div>

                <div class="du-content-subtitle du-request-subtitle mt-0">
                  <h3>Betalingsmethode</h3>
                </div>

                <div class="du-request-payment">
                  <div class="row" :class="{'row-cols-auto': windowCheckerData.isMobileScreen}">

                    <div :class="{
                      'cpbs': windowCheckerData.isMobileScreen || windowCheckerData.isTabletScreen,
                      'col': windowCheckerData.isMobileScreenS,
                      'col-4': windowCheckerData.isMobileScreenXS || windowCheckerData.isTabletScreen,
                      'd-inline-block w-auto':!windowCheckerData.isMobileScreen && !windowCheckerData.isTabletScreen}">
                      <img class="du-payment-img" src="../assets/DRV_betaalmethode_ideal.png" alt="">
                    </div>
                    <div :class="{
                      'cpbs': windowCheckerData.isMobileScreen || windowCheckerData.isTabletScreen,
                      'col': windowCheckerData.isMobileScreen,
                      'col-4': windowCheckerData.isMobileScreenXS || windowCheckerData.isTabletScreen,
                      'd-inline-block w-auto':!windowCheckerData.isMobileScreen && !windowCheckerData.isTabletScreen}">
                      <img class="du-payment-img" src="../assets/DRV_betaalmethode_mastercard.png" alt="">
                    </div>
                    <div :class="{
                      'cpbs': windowCheckerData.isMobileScreen || windowCheckerData.isTabletScreen,
                      'col': windowCheckerData.isMobileScreenS,
                      'col-4': windowCheckerData.isMobileScreenXS || windowCheckerData.isTabletScreen,
                      'd-inline-block w-auto':!windowCheckerData.isMobileScreen && !windowCheckerData.isTabletScreen}">
                      <img class="du-payment-img" src="../assets/DRV_betaalmethode_paypal.png" alt="">
                    </div>

                  </div>

                  <div class="du-question-controls du-controls-content-smallmargin d-flex justify-content-between mt-5">

                      <router-link to="/start-aanvraag">
                        <div class="du-question-control-left d-inline-block">
                          <button class="du-button du-button-back">
                            <i class="fa-solid fa-chevron-left thick-icon"></i>
                            TERUG
                          </button>
                        </div>
                      </router-link>

<!--                      <a :href="mollieLink">-->
                        <div class="du-question-control-right d-inline-block">
                          <button class="du-button" v-on:click="submitting = true">
                            BETALEN
                            <i class="fa-solid fa-chevron-right thick-icon"></i>
                          </button>
                        </div>
<!--                      </a>-->
                  </div>

                </div>
              </div>
              </form>
            </div>
        </div>
      </div>

      <div class="d-md-block d-none col-md-6 col-12 du-column-bg-white" :class="{'cptl cpm min-height': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen}">
        <div class="row">
          <div class="col-12 du-data-overview position-relative" v-if="shopItem.length > 0">
            <div :class="{'du-data-overview-fixed cpm': !windowCheckerData.isMobileScreen}">
              <div class="du-content-title du-data-title mt-0 mb-4 text-center w-100">
                <h2>WINKELMAND</h2>
              </div>
              <div class="du-data-overview-datatitle">
                <h3>NAAM</h3>
              </div>
              <div class="col-12 d-flex justify-content-center">
                <div class="du-data-overview-seperator-thick"></div>
              </div>
              <div class="col-12">
                <div class="du-data-overview-data">
                  <div class="row">
                    <div class="col-9">
                      <div class="du-data-overview-text">
                        <h4>{{ shopItem[0].sto_name }}</h4>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="du-data-overview-text du-data-overview-text-last d-flex justify-content-end">
                        <h4>€ {{  shopItem[0].sto_price_excl_btw }}</h4>
                      </div>
                    </div>
                  </div>
                </div>
<!--                <div class="du-data-overview-seperator"></div>-->
              </div>
              <div class="col-12">
                <div class="du-data-overview-data">
                  <div class="row">
                    <div class="col-9">
                      <div class="du-data-overview-text">
                        <h4>{{ shopItem[0].btw_name }}</h4>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="du-data-overview-text du-data-overview-text-last d-flex justify-content-end">
                        <h4>€ {{  calculatePrice("btw", shopItem[0].sto_price_excl_btw, shopItem[0].btw_value) }}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="du-data-overview-seperator"></div>
              </div>
              <div class="col-12" v-if="usingDiscount">
                <div class="du-data-overview-data">
                  <div class="row">
                    <div class="col-9">
                      <div class="du-data-overview-text">
                        <h4>KORTING</h4>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="du-data-overview-text du-data-overview-text-last d-flex justify-content-end">
                        <h4>- € {{ discount.cod_amount }}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="du-data-overview-seperator"></div>
              </div>
              <div class="col-12">
                <div class="du-data-overview-data">
                  <div class="row">
                    <div class="col-9">
                      <div class="du-data-overview-text">
                        <h4>TOTAAL</h4>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="du-data-overview-text du-data-overview-text-last d-flex justify-content-end">
                        <h4>€ {{ calculatePrice("total", shopItem[0].sto_price_excl_btw, shopItem[0].btw_value) }}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="du-data-overview-seperator"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>
</template>

<script>
import WindowChecker from "@/components/WindowChecker.vue";
import {useTest} from "@/components/utils/crud-test";
import {checkPermissionByEmail} from "@/components/utils/check-permission-by-email";
import {computed, onMounted, reactive, ref} from "vue";
import {useTheme} from "@/components/utils/theme";
import {useRouter} from "vue-router";
export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  name: "RequestForm.vue",
  components: {WindowChecker},
  setup() {
    const {isUserExclusive} = checkPermissionByEmail();
    const { isDarkPage } = useTheme()
    const router = useRouter();
    const errors = ref({});
    const form = reactive({
      surname: '',
      name: '',
      company: '',
      btwNumber: '',
      address: '',
      zipcode: ''
    });

    const isSurnameValid = computed(() => form.surname.trim() !== '');
    const isNameValid = computed( () => form.name.trim() !== '');
    const validateField = async (field, msg = '') => {
      if (field === 'surname' && !isSurnameValid.value) {
        errors.value[field] = ''; // Clear previous error for the field
        errors.value.surname = 'Voornaam is verplicht.';
      }
      if (field === 'name' && !isNameValid.value) {
        errors.value[field] = ''; // Clear previous error for the field
        errors.value.name = "Achternaam is verplicht.";
      }
      if (field === 'discount') {
        errors.value[field] = ''; // Clear previous error for the field
        errors.value.discount = msg;
      }

    };

    const redirectToPageWithParam = (paymentStatus) => {
      router.push({ name: 'Payment', params: { paymentStatus } });
    };

    const submitForm = async () => {
      errors.value = {}; // Clear previous errors
      validateField('surname');
      validateField('name');
      // Add more validation checks for other form fields as needed
      if (Object.keys(errors.value).length === 0) { //if no errors are found
        // Validation passed, implement handling of form submission
        const obj = {
          surname: form.surname,
          name: form.name,
          company: form.company,
          btwNumber: form.btwNumber,
          address: form.address,
          zipcode: form.zipcode,
        };

        const requestOptions = {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(obj),
        };
        return true;
      } else {
        return false
      }
    };
    return {
      isDarkPage,
      isUserExclusive,
      errors,
      form,
      isSurnameValid,
      isNameValid,
      submitForm,
      validateField,
      redirectToPageWithParam
    }
  },
  data() {
    return {
      submitting: false,
      molliePrice: 0.00,
      discount: [],
      usingDiscount: false,
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
      options: [
        {id: '1', name: 'Geen rekening houden met vennootschapsbelasting'},
        {id: '2', name: 'Rekening houden met vennootschapsbelasting ad 20%'},
        {id: '3', name: 'Rekening houden met vennootschapsbelasting ad 25%'},
      ],
      shopItem: [],
    }
  },
  async mounted() {
    const obj = {
      selectAll: "false",
      selectedColumns: [
        "shop.*",
        "btw.*"
      ],
      from: "drv_shopping_store AS shop",
      orderBy: "sto_id ASC",
      testQuery: "false",
      join: {
        st1: 'INNER JOIN drv_btw AS btw ON shop.btw_id = btw.btw_id'
      }
    };
    const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(obj),
    };
    const response = await fetch(API_BASE_URL + "/dynamicGet/index.php", requestOptions);
    const json = await response.json();

    if (json.success === true && json.data !== false) {
      this.shopItem = json.data;
    } else {
      this.shopItem = [];
    }
  },
  methods: {
    async isDiscountValid(code){
      if(code){
        const user = localStorage.getItem("user");
        if(user){
          const obj = {
            selectAll: "true",
            from: "drv_discount_codes",
            orderBy: "cod_id ASC",
            testQuery: "false",
            where:{
              st1: "cod_code = '" + code + "'",
              st2: "AND cod_emailaddress = '" + JSON.parse(user).data.email + "'",
            }
          };
          const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(obj),
          };
          const response = await fetch(API_BASE_URL + "/dynamicGet/index.php", requestOptions);
          const json = await response.json();
          if (json.success === true && json.data !== false && json.data.length > 0) {
            const data = json.data[0];
            if(data.cod_status == 1){
              const isValid = this.isDiscountCodeValid(data.cod_start_datetimestamp, data.cod_end_datetimestamp);
              if(isValid){
                this.validateField('discount', '')
                this.discount = json.data[0]
                this.usingDiscount = true;
              }
              else{
                this.validateField('distcount', 'Kortingscode is ongeldig. Probeer het nog eens.')
              }
            }
            else{
              this.validateField('discount', 'Kortingscode is al gebruikt. Probeer het nog eens.')
            }
          }
          else{
            this.validateField('discount', 'Kortingscode is ongeldig. Probeer het nog eens.')
          }
        }
      }
    },

    isDiscountCodeValid(startDate, endDate) {
      const now = new Date();
      const start = new Date(startDate);
      const end = new Date(endDate);

      return now >= start && now <= end;
    },

    async checkOut(){
      if(this.submitting){
        const resp =  await this.submitForm();
        if(resp === true){
          if(this.usingDiscount){
            localStorage.setItem('discount', JSON.stringify(this.discount))
          }
          if(this.molliePrice <= 0){
            this.redirectToPageWithParam('succes')          }
          else{
            const user = localStorage.getItem("user")
            if(user){
              localStorage.setItem('price', this.molliePrice)
              window.location.href = `${API_BASE_URL}/mollie/index.php?price=${this.molliePrice}&user_id=${JSON.parse(user).data.userId}`;
            }
          }
        }
      }
    },
    windowChecker(data) {
      this.windowCheckerData = data;
    },
    calculatePrice(type, price, btw){
      const totalPrice = this.calculateByTwoDecimal(Number(price) * Number(btw));
      switch(type){
        case "btw":
          return this.calculateByTwoDecimal(totalPrice - Number(price))
        case "total":
          this.molliePrice = totalPrice;
          if(this.usingDiscount){
            this.molliePrice = this.calculateByTwoDecimal(totalPrice - Number(this.discount.cod_amount))
          }
          return this.molliePrice;

        default:
          return totalPrice;
      }
    },

    calculateByTwoDecimal(result){
      return result.toFixed(2);
    }
}

}
</script>

<style scoped>
  .du-input-discount{
    width:350px;
    margin-right:1rem;
  }
</style>
