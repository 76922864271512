<template>
  <title>Overzicht | Dividenduitkering</title>

  <div class="container-fluid" :class="{'cpbm': windowCheckerData.isMobileScreen}">
    <div class="row">
      <div class="d-md-none d-block col-12 du-column-bg-white" :class="{'cptl cpbl cpxxl min-height': !windowCheckerData.isMobileScreen, 'cpm' : windowCheckerData.isMobileScreen}">
        <div class="row">
          <div class="col-12 d-flex justify-content-start">
            <div class="du-content-title du-data-title mt-0 mb-4">
              <h2>AANVRAGEN</h2>

            </div>
          </div>
        </div>
        <div class="nav nav-tabs">
          <div class="tab-item" v-on:click="switchTestsDisplayMode('own')" :class="{'active': displayTestMode == 'own'}">
            <p>Mijn toetsen</p>
          </div>
          <div v-if="isUserExclusive" class="tab-item" v-on:click="switchTestsDisplayMode('all')" :class="{'active': displayTestMode == 'all'}">
            <p>Andere toetsen</p>
          </div>
        </div>
        <div class="row">
          <div class="col-4 mb-1">
            <div class="du-data-overview-datatitle">
              <h3>NAAM</h3>
            </div>
          </div>

          <div class="col-3 mb-1">
            <div class="du-data-overview-datatitle">
              <h3>DATUM</h3>
            </div>
          </div>

          <div class="col-2 mb-1">
            <div class="du-data-overview-datatitle">
              <h3>STATUS</h3>
            </div>
          </div>
        </div>
        <div class="row">

          <div class="col-12 d-flex justify-content-center">
            <div class="du-data-overview-seperator-thick"></div>
          </div>

          <div v-if="displayTests.length > 0" v-for="opt in displayTests" class="col-12">
            <div class="du-data-overview-data">
              <div class="row">
                <div class="col-4 d-flex align-items-center">
                  <div class="du-data-overview-text">
                    <h4 v-if="opt.test_name">{{opt.test_name}}</h4>
                    <h4 v-if="opt.test_name == null">---</h4>
                  </div>
                </div>
                <div class="col-3 d-flex align-items-center">
                  <div class="du-data-overview-text">
                    <p>{{ opt.test_custom_datetimestamp }}</p>
                  </div>
                </div>
                <div class="col-2 d-flex align-items-center">
                  <div class="du-data-overview-text">
                    <p>{{ opt.test_status }}</p>
                  </div>
                </div>
                <div class="col-3 d-flex align-items-center">
                  <div v-if="!windowCheckerData.isMobileScreen && windowCheckerData.isTabletScreen" class="du-data-overview-text du-data-overview-text-last du-data-overview-link d-flex justify-content-end">
                    <router-link :to="{name: 'QuestionList', params:{id: opt.test_unique_id}}">
                      <h4>NAAR TOETS <i class="fa-solid fa-chevron-right thick-icon"></i></h4>
                    </router-link>
                  </div>
                  <span v-if="windowCheckerData.isMobileScreen">
                    <div class="download-pdf d-flex flex-column align-items-center">
                      <span>
                        <i class="fa-solid fa-file-pdf"></i>
                        <!--                              <i class="fa-solid fa-download"></i>-->
                        <!--                              <i class="fa-solid fa-file-arrow-down"></i>-->
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="du-data-overview-seperator"></div>
          </div>

          <div v-if="displayTests.length > 0" class="col-12">
            <div class="du-data-overview-data">
              <h3>ER ZIJN NOG GEEN AANVRAGEN UITGEVOERD.</h3>
            </div>
          </div>

        </div>
      </div>

      <div class="col-md-4 col-12 container-content" :class="{'cptl cpxxl cpbm min-height': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen, 'font-white': isDarkPage}">

        <div class="cmtm">
          <div class="cmbm">
            <h2 class="largeTextXl">Overzicht toetsen</h2>
          </div>
          <div class="du-controls-content d-flex">
            <div class="w-100">
              <div class="du-question-control-left d-md-inline-block d-block cmbs" :class="{'w-100': windowCheckerData.isTabletScreen}">
                <div class="cmbm">
                  <p>Dit is het overzicht van al de lopende toetsen die u nu kunt benaderen wanneer u bent ingelogd.</p>
                </div>
                <div class="cmbm">
                  <p>Totdat de toets wordt afgerond, zullen de ingevoerde gegevens maximaal 60 dagen bewaard blijven sinds het aanmaken van de toets.</p>
                </div>
                <router-link to="/nieuwe-aanvraag" v-if="!isUserExclusive">
                  <button class="du-button">NIEUWE AANVRAAG
                    <i class="fa-solid fa-chevron-right thick-icon"></i>
                  </button>
                </router-link>
                <button class="du-button" v-if="isUserExclusive" @click="handleCreateTest">NIEUWE AANVRAAG
                  <i class="fa-solid fa-chevron-right thick-icon"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-md-block d-none col-md-8 col-12 du-column-bg-white" :class="{'cptl cpxxl min-height': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen}">
        <div class="row">
          <div class="col-12 d-flex justify-content-start">
            <div class="du-content-title du-data-title mt-0 mb-4 d-flex align-items-center">
              <h2>AANVRAGEN</h2>
              <button class="refresh-button" v-on:click="refreshData" v-if="isUserExclusive">
                <span class="refresh-icon d-block" :class="{'spin-icon': loadingRefreshIcon}">
                  <i class="fa-solid fa-arrows-rotate"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="overview-tabs">
          <div class="tab-item" v-on:click="switchTestsDisplayMode('own')" :class="{'active': displayTestMode == 'own'}">
            <p>Mijn toetsen</p>
          </div>
          <div v-if="isUserExclusive" class="tab-item" v-on:click="switchTestsDisplayMode('all')" :class="{'active': displayTestMode == 'all'}">
            <p>Andere toetsen</p>
          </div>
        </div>
        <div class="row">
          <div class="d-flex align-items-center" :class="{'col-4': displayTestMode == 'own', 'col-3': displayTestMode == 'all'}">
            <div class="du-data-overview-datatitle">
              <h3>NAAM</h3>
            </div>
          </div>

          <div class="col-3 d-flex align-items-center">
            <div class="du-data-overview-datatitle">
              <h3>DATUM</h3>
            </div>
          </div>

          <div class="align-items-center" :class="{'d-none': displayTestMode == 'own', 'd-flex col-2': displayTestMode == 'all'}">
            <div class="du-data-overview-datatitle">
              <h3>GEMAAKT DOOR</h3>
            </div>
          </div>

          <div class="col-2 d-flex align-items-center">
            <div class="du-data-overview-datatitle">
              <h3>STATUS</h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <div class="du-data-overview-seperator-thick"></div>
          </div>
          <div v-if="displayTests.length > 0" v-for="opt in displayTests" class="col-12" :class="{'disabled-row' : (opt.test_current_user_id && opt.test_last_visited_datetimestamp) && (opt.test_current_user_id != userId)}">
            <div class="du-data-overview-data">
              <div class="row">
                <div class="d-flex align-items-center" :class="{'col-4': displayTestMode == 'own', 'col-3': displayTestMode == 'all'}">
                  <div class="du-data-overview-text">
                    <h4 v-if="opt.test_name">{{opt.test_name}}</h4>
                    <h4 v-if="opt.test_name == null">---</h4>
                  </div>
                </div>
                <div class="col-3 d-flex align-items-center">
                  <div class="du-data-overview-text">
                    <p>{{ opt.test_custom_datetimestamp }}</p>
                  </div>
                </div>
                <div class="align-items-center" :class="{'d-none': displayTestMode == 'own', 'd-flex col-2': displayTestMode == 'all'}">
                  <div class="du-data-overview-text">
                    <img  class="userPicture"
                          :src="opt.user_picture"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          :title="opt.user_nickname">
                  </div>
                </div>
                <div class="d-flex align-items-center" :class="{'col-2' : !opt.test_current_user_id && !opt.test_last_visited_datetimestamp, 'col-3': opt.test_current_user_id && opt.test_last_visited_datetimestamp}">
                  <div class="du-data-overview-text" :class="{'d-flex align-items-center': opt.test_current_user_id && opt.test_last_visited_datetimestamp}">
                    <p>{{ opt.test_status }}</p>
                    <div v-if="opt.test_current_user_id && opt.test_last_visited_datetimestamp">
                      <div class="du-data-overview-text">
                        <img  class="userPicture cmssx"
                              :src="opt.current_user_picture"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              :title="opt.current_user_nickname">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center"  :class="{'col-3': displayTestMode === 'own' && opt.test_current_user_id != userId, 'col-2': displayTestMode === 'all' || (displayTestMode === 'own' && opt.test_current_user_id == userId)}">
                  <div v-if="!windowCheckerData.isMobileScreen && !windowCheckerData.isTabletScreen && !opt.test_current_user_id && !opt.test_last_visited_datetimestamp" class="du-data-overview-text du-data-overview-text-last du-data-overview-link d-flex justify-content-end">
                    <router-link :to="{name: 'QuestionList', params:{id: opt.test_unique_id}}">
                      <h4>NAAR TOETS <i class="fa-solid fa-chevron-right thick-icon"></i></h4>
                    </router-link>
                  </div>
                  <div v-if="!windowCheckerData.isMobileScreen && !windowCheckerData.isTabletScreen && opt.test_current_user_id == userId && opt.test_last_visited_datetimestamp" class="du-data-overview-text du-data-overview-text-last du-data-overview-link d-flex justify-content-end">
                    <h4 v-on:click="endSessionTest(opt)">BEËINDIG SESSIE <i class="fa-solid fa-chevron-right thick-icon"></i></h4>
                  </div>
                  <span v-if="windowCheckerData.isMobileScreen || windowCheckerData.isTabletScreen">
                    <div class="download-pdf d-flex flex-column align-items-center">
                      <span>
                        <i class="fa-solid fa-file-pdf"></i>
                        <!--                              <i class="fa-solid fa-download"></i>-->
                        <!--                              <i class="fa-solid fa-file-arrow-down"></i>-->
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="du-data-overview-seperator"></div>
          </div>

          <div v-if="displayTests.length == 0" class="col-12">
            <div class="du-data-overview-data">
              <h3 v-if="displayTests.length == 0">ER ZIJN NOG GEEN AANVRAGEN UITGEVOERD.</h3>
              <h3 v-if="isLoading">LADEN VAN AANVRAGEN...</h3>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>

</template>

<script>
import WindowChecker from "@/components/WindowChecker.vue";
import {useAuth0} from "@auth0/auth0-vue";
import {checkPermissionByEmail} from "@/components/utils/check-permission-by-email"
import {useTest} from "@/components/utils/crud-test";
import {onMounted, ref} from "vue";
import {useTheme} from "@/components/utils/theme";
export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {

  name: "RequestOverview",
  components: {WindowChecker},
  setup() {
    const { isDarkPage } = useTheme()
    const { isUserExclusive } = checkPermissionByEmail();
    const { createTest } = useTest();
    const auth0 = useAuth0();
    const tests = ref([]);
    const displayTests = ref([]);
    const displayOwnTests = ref([]);
    const displayOtherTests = ref([]);
    const displayTestMode = ref('own');
    const userId = ref('');
    const loadingRefreshIcon = ref(false);
    const storageUser = localStorage.getItem('user');
    const refreshData = async () => {
      loadingRefreshIcon.value = true
      displayOwnTests.value = []
      displayOtherTests.value = []
      setTimeout(()=>{
        getRequests()
      },100)
    }
    // Definieer de getRequests functie
    const getRequests = async () => {
      if (storageUser) {
        userId.value = JSON.parse(storageUser).data.userId;
        if (userId.value) {
          const obj = {
            selectAll: "false",
            selectedColumns : [
                "test.*",
                "user1.*",
                "user2.user_id AS current_user_id",
                "user2.user_email AS current_user_email",
                "user2.user_email_verified AS current_user_email_verified",
                "user2.user_name AS current_user_name",
                "user2.user_nickname AS current_user_nickname",
                "user2.user_picture AS current_user_picture",
                "user2.user_sub AS current_user_sub",
                "user2.user_updated_at AS current_user_updated_at"
            ],
            from: "drv_tests AS test",
            orderBy: "test_id DESC",
            testQuery: "false",
            join: {
              st1: ''
            }
          };
          if(isUserExclusive.value){
            obj.join.st1 = `LEFT JOIN drv_users AS user1 ON test.user_id = user1.user_id`
            obj.join.st2 = `LEFT JOIN drv_users AS user2 ON test.test_current_user_id = user2.user_id WHERE user1.user_intern = 1`
          }
          else{
            obj.join.st1 = `LEFT JOIN drv_users AS user1 ON test.user_id = user1.user_id`
            obj.join.st2 = `LEFT JOIN drv_users AS user2 ON test.test_current_user_id = user2.user_id WHERE test.user_id = ${userId.value}`
          }
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(obj),
          };
          const response = await fetch(API_BASE_URL + "/dynamicGet/index.php", requestOptions);
          const json = await response.json();

          if (json.success === true && json.data !== false) {
            tests.value = json.data;
            displayOwnTests.value = tests.value.filter(test => test.user_id === userId.value);
            if(isUserExclusive){
              displayOtherTests.value = tests.value.filter(test => test.user_id !== userId.value);
            }
          }
          else{
            tests.value = [];
          }
          switchTestsDisplayMode();
        }
      }
    };

    // Methode om createTest te triggeren op button click
    const handleCreateTest = async () => {
      const result = await createTest();
      if (result) {
        await getRequests(); // Direct de lijst met tests bijwerken na het aanmaken van een nieuwe test
      }
    };

    const switchTestsDisplayMode = (mode) => {
      if(!mode){
        mode = displayTestMode.value;
      }
      displayTestMode.value = mode;
      if (tests.value.length > 0) {
        if (displayTestMode.value === 'own') {
          displayTests.value = displayOwnTests.value;
        } else {
          displayTests.value = displayOtherTests.value
        }
      }
      else{
        displayTests.value = []
      }

      loadingRefreshIcon.value = false
    };

    // Roep getRequests aan wanneer de component wordt geladen
    onMounted(() => {
      getRequests('own'); // Haal de tests op bij het laden van de pagina
    });

    return {
      userId,
      isDarkPage,
      isAuthenticated: auth0.isAuthenticated,
      isLoading: auth0.isLoading,
      user: auth0.user,
      isUserExclusive,
      handleCreateTest, // Voor de button click
      getRequests, // Toegankelijk in de template of andere methodes
      refreshData,
      switchTestsDisplayMode,
      loadingRefreshIcon,
      tests,
      displayTests,
      displayTestMode
    };
  },

  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
      finishedDbCall: false
    }
  },

  watch: {
    isLoading: function (value) {
      if (value === false) {
        this.getRequests()
      }
    }
  },


  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },

    redirectToPDF(token) {
      this.$router.push('/pdf?token='+token)
    },

    async endSessionTest(test) {
      let status;
      // if(!this.notAllowedToEnterTest){

      if (test.test_name) {
        status = 'lopend'
      } else {
        status = 'nieuw'
      }
      const obj = {
        remove_current_user: true,
        test_current_user_id: test.current_user_id,
        test_status: status,
        test_unique_id: test.test_unique_id,
      };

      const requestOptions = {
        method: "PUT",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(obj),
      };
      const response = await fetch(API_BASE_URL + "/tests/index.php", requestOptions);
      if (response) {
        await this.refreshData()
      }
    }

    // switchTestsDisplayMode(mode){
    //   this.displayTestMode = mode;
    //   if(this.tests.length > 0){
    //     if(this.displayTestMode === 'own'){
    //       this.displayTests = this.tests.filter(test => test.user_id === this.userId)
    //     }
    //     else{
    //       const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'))
    //       const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    //         return new bootstrap.Tooltip(tooltipTriggerEl)
    //       })
    //       this.displayTests = this.tests.filter(test => test.user_id !== this.userId)
    //     }
    //   }
    // },
  },

  created() {
  },

  mounted() {
    const user = this.user
    if (!this.isLoading) {
      this.getRequests()
    }
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'))
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }


}
</script>

<style scoped>
.disabled-row{
  opacity: .35;
}

.refresh-button{
  min-width: 50px;
  margin-left: 1rem;
}

.cmssx{
  margin-left: .75rem;
}
  .overview-tabs{
    display: flex;
    margin-bottom:  1rem;
    border-bottom: 1px solid #d9d9d9;
    .tab-item{
      background: #d9d9d9;
      padding: 8px 16px;
      border: 1px solid #d9d9d9;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      &:hover{
        background: #dddddd;
        p{
          text-decoration: underline;
          cursor:pointer;
        }
      }
      &.active{
        background: white;
        border-bottom-color:white;
      }
      p{
        border-bottom: 0px;
      }
    }
  }
  .refresh-button{
    text-align: center;
    background-color: var(--btnBlue);
    border: none;
    font-size: 15px;
    padding: 6px 18px 6px 18px;
    transition: none;
    letter-spacing: 0.6px;
    vertical-align: baseline;
  }
.refresh-icon{
  transform: rotate(0deg)
}

.spin-icon{
  animation-name: refresh-animation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes refresh-animation {
  from {
    transform: rotate(0deg)}
  to {
    transform: rotate(3600deg)}
}
</style>
